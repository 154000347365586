<template>
  <button
    :class="[locale === 'ru' && $style.active, $style['localization-button']]"
    @click="handleClick('ru')"
  >
    <span :class="['fi', 'fi-ru']"></span>
    <span>Русский</span>
  </button>
  <button
    :class="[locale === 'en' && $style.active, $style['localization-button']]"
    @click="handleClick('en')"
  >
    <span :class="['fi', 'fi-us']"></span>
    <span>English</span>
  </button>
  <button
    v-if="uzbekLangEnabled"
    :class="[locale === 'uz' && $style.active, $style['localization-button']]"
    @click="handleClick('uz')"
  >
    <span :class="['fi', 'fi-uz']"></span>
    <span>O'zbek</span>
  </button>
</template>

<script setup lang="ts">
import "/node_modules/flag-icons/css/flag-icons.min.css";

const uzbekLangEnabled = useFlag("uzbek_lang");
const { locale, setLocale } = useI18n();
const coreStore = useCoreStore();
const handleClick = (value: string) => {
  setLocale(value);
  coreStore.hideLanguageSubmenu();
};
</script>

<style lang="scss" module>
.localization-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 0px;
  padding-left: 24px;
  font-size: 0.875rem;
  color: var(--general-white);
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;
  gap: 8px;
  transition: 0.25s;
  span {
    margin-right: 8px;
  }

  &.active,
  &:hover {
    background: var(--button-ghost-active);
  }

  svg {
    transition: 0.25s;
  }
}
</style>
