<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95465 3.74757C5.5703 2.05609 7.00845 0.901732 8.65305 0.571769C8.5886 0.97877 8.53364 1.5401 8.56444 2.18144C8.60466 3.0188 8.77733 3.70639 8.89762 4.09581C8.57768 4.26815 8.31733 4.55404 8.18326 4.92239L7.57134 6.60363C7.18967 6.3892 6.68419 6.06696 6.16754 5.62696C5.52444 5.07929 5.06421 4.51964 4.80252 4.16554L4.95465 3.74757Z"
    />
    <path
      d="M10.1517 4.00452C10.0593 3.9709 9.96581 3.9467 9.87224 3.93142C9.7555 3.52751 9.60932 2.88894 9.57302 2.133C9.54113 1.46893 9.60231 0.886902 9.67019 0.475098C10.2208 0.479923 10.78 0.577017 11.327 0.776094C12.9436 1.36448 14.0696 2.70413 14.4541 4.25728C14.0527 4.19189 13.5123 4.13659 12.8977 4.15914C11.9528 4.19381 11.1965 4.39912 10.8447 4.51231C10.6728 4.28795 10.437 4.10836 10.1517 4.00452Z"
    />
    <path
      d="M11.1625 5.43895C11.1636 5.61618 11.1337 5.79661 11.0695 5.97291L10.6362 7.16362C10.3116 8.05529 10.7714 9.04123 11.663 9.36578L11.7347 9.39186C12.5868 9.702 13.529 9.26266 13.8391 8.41057L14.2985 7.14845C14.5225 6.53296 14.6174 5.90195 14.597 5.2851C14.1856 5.2122 13.6021 5.14371 12.9347 5.1682C12.1965 5.19528 11.569 5.3283 11.1625 5.43895Z"
    />
    <path
      d="M4.40304 5.26312L2.96236 9.22134C3.23522 9.56814 3.64331 10.0315 4.17763 10.4865C4.78271 11.0018 5.37248 11.3556 5.76598 11.5638L7.23033 7.54055C6.84617 7.34678 6.19224 6.97428 5.51286 6.39571C5.04187 5.9946 4.67061 5.5898 4.40304 5.26312Z"
    />
    <path
      d="M3.52295 11.2552C4.33133 11.9437 5.10368 12.3403 5.4284 12.4913L4.7359 14.3939C4.41141 15.2855 3.42563 15.7451 2.5341 15.4207C1.64257 15.0962 1.1829 14.1104 1.50739 13.2189L2.56756 10.306C2.81749 10.5948 3.13668 10.9263 3.52295 11.2552Z"
    />
  </svg>
</template>
